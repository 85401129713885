
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { mapGetters } from 'vuex'
export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      },
    },
    title: {
      default: '',
    },
    poster: {
      default: '',
    },
    videoLink: {
      default: () => ({
        token: '',
        link: '',
      }),
    },
  },
  data() {
    return {
      player: null,
      duration: 0,
    }
  },
  computed: {
    ...mapGetters({
      getActiveUser: 'user/getActiveUser',
    }),
  },
  mounted() {
    const vm = this
    if (this.getActiveUser.token)
      videojs.Vhs.xhr.beforeRequest = (option) => {
        // option.headers = {
        //   authorization: `Bearer ${this.getActiveUser.token}`,
        // }
        if (!option.uri.includes('part.m3u8'))
          option.uri = option.uri + '?jwt_token=' + vm.videoLink.token
        return option
      }
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      var videoTitle = vm.player.controlBar.addChild('button', {}, 6)
      var videoTitleDom = videoTitle.el()
      videoTitleDom.innerHTML = this.title
      videoTitle.addClass('video-title')
      setTimeout(() => {
        this.$emit('video:init', vm.player)
      }, 150)
      this.player.on('loadedmetadata', function (e) {
        vm.$emit('video:loadedmetadata', vm.player)
      })
      this.player.on('error', function (e) {
        vm.$emit('error', vm.player)
      })
      this.player.on('play', function (e) {
        vm.$emit('video:play', vm.player)
      })
      this.player.on('playing', function (e) {
        vm.$emit('video:playing', vm.player)
      })

      this.player.on('pause', function (e) {
        vm.$emit('video:pause', vm.player)
      })

      this.player.on('seeking', function (e) {
        vm.$emit('video:seeking', vm.player)
      })

      this.player.on('seeked', function (e) {
        vm.$emit('video:seeked', vm.player)
      })
      this.player.on('timeupdate', function (e) {
        const d = Math.ceil(vm.player.currentTime())
        if (d !== vm.duration) {
          vm.$emit('video:playing', vm.player)
          vm.duration = d
        }
      })

      this.player.on('ended', function (e) {
        vm.$emit('video:ended', vm.player)
      })
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
}
